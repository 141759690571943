import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { CommonService } from "src/app/service.service";
import { Router } from "@angular/router";
declare var $;
@Component({
  selector: 'finol-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isLoggedIn$: Observable<object>;
  constructor(private authService: AuthService, private httpService: CommonService, private router: Router) {
    
  }

  ngOnInit() {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  onLogin() {
    $("#login-modal").modal("show");
  }
  
  onLogout(){
    this.authService.logout();
    this.httpService.showSuccess("You have successfully logged out!");
    this.router.navigate([""]);
  }
}
