import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"]
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  backToHomePage() {
    this.router.navigate([""]);
  }

  openChat() {
    (window as any).olark("api.box.expand");
  }
}

/*import {Component, Inject, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {Response} from 'express';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              @Optional() @Inject(RESPONSE) private response: Response) {
  }


  ngOnInit() {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.status(404);
    }
  }

}*/

/*getQuoteBigCard(quote) {
		(window as any).dataLayer = (window as any).dataLayer || [];
		(window as any).dataLayer.push({
			product_name: this.product.name,
			event: "click_on_get_a_quote"
		});
		window.open(quote,"_self");
	}*/
