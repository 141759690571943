export class CONFIG_CONSTANTS {
  path = {
     //node_url: "https://localhost:3000"
    node_url: "https://testproxy.tricel-test.eu",
    api_url: "https://admin.finol.ie/api"
    //api_url: "https://finol.24livehost.com/public/api"
  };
  error_messages = {
    field_empty: "This field is required",
    registration_number_incorrect:
      "Incorrect Registration Number • Is your reg plate a car or van and < 7.5t? • Is the reg format correct? e.g. 181-D-1234 or 181D1234",
    unknown_error: "Unknown Error Occured"
  };
  field_placeholders = {
    machine_or_vehicle_title: "Select Vehicle or Machine",
    make_title: "Select Make",
    model_title: "Select Model",
    type_title: "Select Type"
  };
  magentoImageUrl = "https://catalog.finol.ie/pub/media/catalog/product";
  imagePath = {
    Engine:
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Engine-Oil-Icon.svg",
    "Power steering":
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Power-Steering-Icon.svg",
    Antifreeze:
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Antifreeze-Icon.svg",
    "GEAR/ATF":
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Gear-Icon.svg",
    "Transaxle, automatic":
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Default-Icon.svg",
    "Transaxle, manual":
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Default-Icon.svg",
    "Hydraulic brake system":
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Clutch-Icon.svg",
    "Power steering, combined with levelling system":
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Default-Icon.svg",
    default:
      "https://www.finol.ie/which-oil/app/assets/images/Choose-Category-Icons/SVG-Icons/Default-Icon.svg",
    Total:
      "https://www.finol.ie/which-oil/app/assets/images/Filter-By-Brand-Images/FBB_Total.png",
    Finol:
      "https://www.finol.ie/which-oil/app/assets/images/Filter-By-Brand-Images/FBB_Finol.png",
    Elf:
      "https://www.finol.ie/which-oil/app/assets/images/Filter-By-Brand-Images/FBB_Elf.png",
    tick:
      "https://www.finol.ie/which-oil/app/assets/images/Filter-By-Brand-Images/FBB_Tick.png"
  };
  mainImages = {
    Engine:
      "https://www.finol.ie/which-oil/app/assets/images/Car-Results-Icons/Engine-Oil-Icon.png",
    "Power steering":
      "https://www.finol.ie/which-oil/app/assets/images/Car-Results-Icons/Power-Steering-Icon.png",
    Antifreeze:
      "https://www.finol.ie/which-oil/app/assets/images/Car-Results-Icons/Antifreeze-Icon.png",
    "Hydraulic brake system":
      "https://www.finol.ie/which-oil/app/assets/images/Car-Results-Icons/Hydraulic-Icon.png",
    "GEAR/ATF":
      "https://www.finol.ie/which-oil/app/assets/images/Car-Results-Icons/Transmission-Icon.png",
    Default:
      "https://www.finol.ie/which-oil/app/assets/images/Car-Results-Icons/Default-Icon.png"
  };
};

export const google_captch = {
  // site_key: '6LcJqNEiAAAAACxSVGbQcfXSJ5KtCcvpOvbLISgI',
  site_key: '6Le4xtYiAAAAAE5zW0xhX_WhleiahZvdNBqqcyaE',
}
