import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class AuthService {
  public loggedIn = new BehaviorSubject<Object>(JSON.parse(localStorage.getItem("user")));

  constructor() {

  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  setSessionData(data) {
    localStorage.setItem("user", JSON.stringify(data));
    this.loggedIn.next(data);
  }

  logout() {
    localStorage.removeItem("user");
    this.loggedIn.next(null);
    // this.httpClientService.goTo('');
  }
}
