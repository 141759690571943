import { Injectable } from '@angular/core';

@Injectable()
export class FilterData {
    private filterArr = [];

    setValue(ip) {
        this.filterArr = [...ip]
    }
    getValue() {
        return this.filterArr;
    }
}
