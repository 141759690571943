import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from "src/app/service.service";
import { CONFIG_CONSTANTS } from "../../../assets/config-constant";
declare var $;

@Component({
  selector: 'finol-forgot-password-alert',
  templateUrl: './forgot-password-alert.component.html',
  styleUrls: ['./forgot-password-alert.component.scss']
})
export class ForgotPasswordAlertComponent implements OnInit {
  submitted:boolean = false;
  submitting:boolean = false;
	ForgotPasswordForm: FormGroup;
  errorMessage: String = null;
  constructor(private cc: CONFIG_CONSTANTS, private httpService: CommonService, private fb: FormBuilder) {
    this.ForgotPasswordForm = fb.group({
			email: ["", [Validators.required, Validators.email]],
	  });
  }

  ngOnInit() {
  }

  get f() { return this.ForgotPasswordForm.controls; }

  ForgotPasswordUser() {
		const self = this;
    this.submitted = true;
    this.errorMessage = null;
    if (this.ForgotPasswordForm.valid) {
			this.submitting = true;
			const sendRequestData = this.ForgotPasswordForm.value;
			this.httpService.doPost(`${this.cc.path.api_url}/request/password/reset`, sendRequestData).subscribe(function(res:any){
        const result = JSON.parse(res);
				self.submitting = false;
				if (result.status) {
          self.ForgotPasswordForm.reset();
					self.httpService.showSuccess(result.message);
          self.httpService.registeredEmail = sendRequestData.email;
          $("#forgot-password-modal").modal("hide");
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          $("#reset-password-modal").modal("show");
          self.submitted=false;
				}
				else {
					self.errorMessage = result.message;
				}
			}
			, error => {
				self.submitting = false;
				self.errorMessage = "Something went wrong";
			});
		}
  }

}
