import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { ReturnStatement } from "@angular/compiler";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CommonService {
  private httpOptions: any;
  public registeredEmail: String;
  constructor(private http: HttpClient, private toastr: ToastrService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      })
    };
  }

  doPost(url, req) {
    this.httpOptions.responseType = "xml";
    return this.http.post(url, req, this.httpOptions);
  }

  doGet(url, params) {
    this.httpOptions.params = params;
    return this.http.get(url, this.httpOptions);
  }

  doGetWithHeader(url) {
    const userData = JSON.parse(localStorage.getItem("user"));
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', "Bearer "+userData.token);
    console.log("this.httpOptions.headers", this.httpOptions.headers)
    return this.http.get(url, this.httpOptions);
  }

  showSuccess(message) {
    console.log("message", message);
    this.toastr.success(message);
  }

  showError(message) {
    this.toastr.error(message);
  }
}
