import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from "src/app/service.service";
import { MustMatch } from '../../helpers/must-match.validator';
import { CONFIG_CONSTANTS } from "../../../assets/config-constant";
declare var $;

@Component({
  selector: 'finol-reset-password-alert',
  templateUrl: './reset-password-alert.component.html',
  styleUrls: ['./reset-password-alert.component.scss']
})
export class ResetPasswordAlertComponent implements OnInit {
  submitted:boolean = false;
  submitting:boolean = false;
	ResetPasswordForm: FormGroup;
  errorMessage: String = null;
  constructor(private cc: CONFIG_CONSTANTS, public httpService: CommonService, private fb: FormBuilder) {
    const special_characters = "!"+'"'+"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    this.ResetPasswordForm = fb.group({
			email: ["", ],
      token: ["", [Validators.required, Validators.maxLength(10)]],
      password: ["", [Validators.required, Validators.pattern(`(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${special_characters}])[A-Za-z\d${special_characters}].{5,}`)]],
			confirm_password: ["", [Validators.required, Validators.pattern(`(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${special_characters}])[A-Za-z\d${special_characters}].{5,}`)]],
	  }, {
      validator: MustMatch('password', 'confirm_password')
    });
  }

  ngOnInit() {}

  get f() { return this.ResetPasswordForm.controls; }

  ResetPasswordUser() {
		const self = this;
    this.submitted = true;
    this.errorMessage = null;
    if (this.ResetPasswordForm.valid) {
			this.submitting = true;
			const sendRequestData = this.ResetPasswordForm.value;
      sendRequestData.email = this.httpService.registeredEmail;
			this.httpService.doPost(`${this.cc.path.api_url}/reset/password`, sendRequestData).subscribe(function(res:any){
        const result = JSON.parse(res);
				self.submitting = false;
				if (result.status) {
          self.ResetPasswordForm.reset();
					self.httpService.showSuccess(result.message);
          self.httpService.registeredEmail = sendRequestData.email;
          $("#reset-password-modal").modal("hide");
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          $("#login-modal").modal("show");
          self.submitted=false;
				}
				else {
					self.errorMessage = result.message;
				}
			}
			, error => {
				self.submitting = false;
				self.errorMessage = "Something went wrong";
			});
		}
  }

}
