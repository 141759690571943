import { Component, OnInit } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "finol-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  today: number = Date.now();
  quarter: number;

  constructor() {
    this.quarter = moment().quarter();
  }

  ngOnInit() {}
}
