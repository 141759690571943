import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
// import { RecommendModule } from './recommend/recommend.module';
import { AppComponent } from "./app.component";
import { AlertComponent } from "./alert/alert.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LottieAnimationViewModule } from "ng-lottie";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { CONFIG_CONSTANTS, google_captch } from "../assets/config-constant";
import { RouterModule, Routes } from "@angular/router";
import { Angulartics2Module } from "angulartics2";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { NotFoundComponent } from './not-found/not-found.component';
import { FilterData } from './filterdata.service';
import * as $ from 'jquery';
import { SignupAlertComponent } from './components/signup-alert/signup-alert.component';
import { LoginAlertComponent } from './components/login-alert/login-alert.component';
import { VerifyEmailAlertComponent } from './components/verify-email-alert/verify-email-alert.component';
import { AuthService } from './auth/auth.service';
import { ForgotPasswordAlertComponent } from './components/forgot-password-alert/forgot-password-alert.component';
import { ResetPasswordAlertComponent } from './components/reset-password-alert/reset-password-alert.component';

import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
@NgModule({
  declarations: [AppComponent, NotFoundComponent,AlertComponent, FooterComponent, HeaderComponent, HomeComponent, SignupAlertComponent, LoginAlertComponent, VerifyEmailAlertComponent, ForgotPasswordAlertComponent, ResetPasswordAlertComponent],
  imports: [
    BrowserModule,
   // RecommendModule,
    Angulartics2Module.forRoot(),
    LottieAnimationViewModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  providers: [
    AlertComponent,
    FilterData,
    CONFIG_CONSTANTS,
    AuthService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: google_captch.site_key,
      } as RecaptchaSettings,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
