import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../helpers/must-match.validator';
import { CommonService } from "src/app/service.service";
import { CONFIG_CONSTANTS } from "../../../assets/config-constant";
declare var $;

@Component({
  selector: 'finol-signup-alert',
  templateUrl: './signup-alert.component.html',
  styleUrls: ['./signup-alert.component.scss']
})
export class SignupAlertComponent implements OnInit {
  submitted:boolean = false;
  submitting:boolean = false;
	signupForm: FormGroup;
  errorMessage: String = null;
  companyNameOptions: String[]  = [
    "Are you a Garage owner?",
    "Individual professional"
  ];
  constructor(private cc: CONFIG_CONSTANTS, private httpService: CommonService, private fb: FormBuilder) {
    const special_characters = "!"+'"'+"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    this.signupForm = fb.group({
			first_name: ["", [Validators.required, Validators.maxLength(100)]],
			last_name: ["", [Validators.required, Validators.maxLength(100)]],
			company_name: ["", [Validators.required, Validators.maxLength(100)]],
			email: ["", [Validators.required, Validators.email]],
			password: ["", [Validators.required, Validators.pattern(`(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${special_characters}])[A-Za-z\d${special_characters}].{5,}`)]],
			confirm_password: ["", [Validators.required, Validators.pattern(`(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[${special_characters}])[A-Za-z\d${special_characters}].{5,}`)]],
			term_of_use: [false, [Validators.required]],
			marketing_email: [false, [Validators.required]],
	    }, {
        validator: MustMatch('password', 'confirm_password')
    });
  }

  get f() { return this.signupForm.controls; }

  ngOnInit() {
  }

  signupUser() {
		const self = this;
    this.submitted = true;
    this.errorMessage = null;
    if (this.signupForm.valid && this.signupForm.value.term_of_use) {
			this.submitting = true;
			var sendRequestData = this.signupForm.value;
      sendRequestData.marketing_email = sendRequestData.marketing_email ? 1 : 0;
			this.httpService.doPost(`${this.cc.path.api_url}/register`, sendRequestData).subscribe(function(res:any){
        const result = JSON.parse(res);
				self.submitting = false;
				if (result.status) {
          self.signupForm.reset();
					self.httpService.showSuccess(result.message);
          self.httpService.registeredEmail = sendRequestData.email;
          $("#signup-modal").modal("hide");
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          $("#verify-email-modal").modal("show");
          self.submitted=false;
				}
				else {
					self.errorMessage = result.message;
				}
			}
			, error => {
				self.submitting = false;
				self.errorMessage = "Something went wrong";
			});
		}
  }

  loginAlert() {
    $("#signup-modal").modal("hide");
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    $("#login-modal").modal("show");
    this.submitted = false;
    this.errorMessage = null;
  }

}
