import { Component, OnInit } from "@angular/core";
import { CommonService } from "src/app/service.service";
import { CONFIG_CONSTANTS } from "../../assets/config-constant";
import { Router } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { LottieAnimationViewModule } from "ng-lottie";
declare var require: any;
declare var $;

@Component({
  selector: "finol-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  title = "my-app";
  public enterYourReg;
  public items: any = [];
  public catag = [];
  private vehicleId;
  private catagName;
  public makeId;
  public modelId;
  public typeId;
  public makeList;
  public modelList;
  public typeList;
  public errorFlag = false;
  public lottieConfig: Object;
  public mobilelottieconfig: Object;
  public machineTitle;
  public makeTitle;
  public modelTitle;
  public typeTitle;
  public errorTitle;
  public nodeURL;
  public isDropdownShow: boolean = false;
  public regPlate;
  public num;
  private anim: any;
  constructor(
    private httpService: CommonService,
    private cc: CONFIG_CONSTANTS,
    private router: Router,
    private pageTitle: Title,
    private pageMeta: Meta,
    @Inject(DOCUMENT) private doc
  ) {
    this.lottieConfig = {
      path: "https://www.finol.ie/which-oil/app/assets/data.json",
      renderer: "canvas",
      autoplay: true,
      loop: false
    };
    this.mobilelottieconfig = {
      path: "https://www.finol.ie/which-oil/app/assets/demo.json",
      renderer: "canvas",
      autoplay: true,
      loop: false
    };
    pageTitle.setTitle("Which Oil Lubricant Advisor - Finol Oils");
    pageMeta.updateTag({
      name: "description",
      content:
        "Find the right oil for your vehicle with the Which Oil Lubricant Advisor and ensure the approved and recommended oils are used in your equipment."
    });
    pageMeta.updateTag({ name: "og:locale", content: "en_GB" });
    pageMeta.updateTag({ name: "og:type", content: "article" });
    pageMeta.updateTag({
      name: "og:title",
      content: "Which Oil Lubricant Advisor - Finol Oils"
    });
    pageMeta.updateTag({
      name: "og:description",
      content:
        "Find the right oil for your vehicle with the Which Oil Lubricant Advisor and ensure the approved and recommended oils are used in your equipment."
    });
    pageMeta.updateTag({
      name: "og:url",
      content: "https://www.finol.ie/which-oil/app/"
    });
    pageMeta.updateTag({ name: "og:site_name", content: "Finol" });
    pageMeta.updateTag({ name: "twitter:card", content: "summary" });
    pageMeta.updateTag({
      name: "twitter:description",
      content:
        "Find the right oil for your vehicle with the Which Oil Lubricant Advisor and ensure the approved and recommended oils are used in your equipment."
    });
    pageMeta.updateTag({
      name: "twitter:title",
      content: "Which Oil Lubricant Advisor - Finol Oils"
    });
    const links = document.getElementsByTagName("link");
    for (let i = 0; i < links.length; i++) {
      if (links[i].rel == "canonical") {
        links[i].href = "https://www.finol.ie/which-oil/app/";
      }
    }

    // const link: HTMLLinkElement = this.doc.createElement('link');
    // link.setAttribute('rel', 'canonical');
    // link.setAttribute('href', 'https://www.finol.ie/which-oil/app/');
    // this.doc.head.appendChild(link);
  }

  ngOnInit() {
    this.makeTitle = this.cc.field_placeholders.make_title;
    this.machineTitle = this.cc.field_placeholders.machine_or_vehicle_title;
    this.modelTitle = this.cc.field_placeholders.model_title;
    this.typeTitle = this.cc.field_placeholders.type_title;
    this.nodeURL = this.cc.path.node_url;

    // if (!(localStorage.getItem("user"))) {
    //   // Commented by param
    //   $("#login-modal").modal("show");
    // }
  }



  handleAnimation(anim: any) {
    this.anim = anim;
  }

  setSearchFilterInLocalStorage() {
    localStorage.setItem("makeTitle", this.makeTitle);
    localStorage.setItem("machineTitle", this.machineTitle);
    localStorage.setItem("modelTitle", this.modelTitle);
    localStorage.setItem("typeTitle", this.typeTitle);

    localStorage.setItem("enterYourReg", this.enterYourReg);

    if(this.vehicleId){
      localStorage.setItem("vehicleId", this.vehicleId);
    }

    if(this.makeId){
      localStorage.setItem("makeId", this.makeId);
    }

    if(this.modelId){
      localStorage.setItem("modelId", this.modelId);
    }

    if(this.typeId){
      localStorage.setItem("typeId", this.typeId);
    }

  }

  ClickedButton1(item, value) {
    if (value == "vehicleId") {
      this.vehicleId = item["$"].id;
      this.machineTitle = item["$"].result;
      this.makeId = undefined;
      this.modelId = undefined;
      this.typeId = undefined;
      this.makeTitle = this.cc.field_placeholders.make_title;
      this.modelTitle = this.cc.field_placeholders.model_title;
      this.typeTitle = this.cc.field_placeholders.type_title;
      //this.catagName = item["$"].result ;
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        vehicle_category: this.machineTitle,
        event: "vehicle_category_selected"
      });
    }
    if (value == "makeId") {
      this.makeId = item["$"].id;
      this.makeTitle = item["$"].result;
      this.modelId = undefined;
      this.typeId = undefined;
      this.modelTitle = this.cc.field_placeholders.model_title;
      this.typeTitle = this.cc.field_placeholders.type_title;
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        vehicle_make: this.makeTitle,
        event: "vehicle_make_selected"
      });
    }
    if (value == "modelId") {
      this.modelId = item["$"].id;
      this.modelTitle = item["$"].result;
      this.typeId = undefined;
      this.typeTitle = this.cc.field_placeholders.type_title;
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        vehicle_model: this.modelTitle,
        event: "vehicle_model_selected"
      });
    }
    if (value == "typeId") {
      this.typeId = item["$"].id;
      this.typeTitle = item["$"].result;
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push({
        vehicle_type: this.typeTitle,
        event: "vehicle_type_selected"
      });
    }
  }
  getModelList() {
    let me = this;
    let parseString = require("xml2js").parseString;
    this.httpService
      .doPost(`${this.nodeURL}/api/getModelList`, {
        modelMakeId: this.makeId
      })
      .subscribe(function(response) {
       // console.log("response in getModelList >>>", response);
        const result = JSON.parse(response.toString());
        me.modelList = result;
      });
  }
  getTypeList() {
    let me = this;
    let parseString = require("xml2js").parseString;
    this.httpService
      .doPost(`${this.nodeURL}/api/getTypeList`, {
        modelId: this.modelId
      })
      .subscribe(function(response) {
        const result = JSON.parse(response.toString());
        me.typeList = result;
      });
  }
  getVehicles() {
    console.log(" IN getVehicles");
    let me = this;
    let parseString = require("xml2js").parseString;
    this.httpService
      .doPost(`${this.nodeURL}/api/getVehicles`, {})
      .subscribe(function(response) {
        //console.log('response in getVehicles >>>', response)
        const result = JSON.parse(response.toString());
        me.catag = result;
      });
  }

  getMakeList() {
    let me = this;
    let parseString = require("xml2js").parseString;
    this.httpService
      .doPost(`${this.nodeURL}/api/getMakeList`, {
        makeId: this.vehicleId
      })
      .subscribe(function(response) {
        //console.log('response in getMakeList >>>', response)
        const result = JSON.parse(response.toString());
        me.makeList = result;
      });
  }

  getData(ip: HTMLElement) {
    const userData = localStorage.getItem("user");
    if (!userData) {
      $("#login-modal").modal("show");
      return false;
    }
    if (!this.enterYourReg || !this.enterYourReg.trim()) {
     // console.log("input is not valid >> ", this.enterYourReg);
      this.errorFlag = true;
      this.errorTitle = this.cc.error_messages.field_empty;
      return;
    }
    this.errorFlag = false;
    let parseString = require("xml2js").parseString;
    let me = this;
    // NODE API CALL
    this.httpService
      .doPost(`${this.nodeURL}/api/fetchTypeDetails`, {
        vehicleNo: this.enterYourReg
      })
      .subscribe(
        response => {
          const result = JSON.parse(response.toString());
          const resObj = result;
          vehicleNo: this.enterYourReg;
          if (resObj) {
            try {
              if (resObj.length > 1) {
                this.items = [...resObj];
                this.scroll(ip);
                //console.log("display");
                (window as any).dataLayer = (window as any).dataLayer || [];
                (window as any).dataLayer.push({
                  reg_plate: this.enterYourReg, // regPlate is the reg number used for the call
                  num_of_type_ids: resObj.length, // num is the number of Type Ids returned
                  event: "reg_search_ok"
                });
              } else {
                (window as any).dataLayer = (window as any).dataLayer || [];
                (window as any).dataLayer.push({
                  reg_plate: this.enterYourReg, // regPlate is the reg number used for the call
                  num_of_type_ids: resObj.length, // num is the number of Type Ids returned
                  event: "reg_search_ok"
                });
                let licenseId = resObj[0]["$"].id;
                this.setSearchFilterInLocalStorage();
                this.router.navigate(["/recommendation", licenseId]);
              }
            } catch (error) {
             // console.log("got error!!!", error);
            }
          }
          //console.log("response");
        },
        function(error) {
          const err = JSON.parse(error.error);
         // console.log("error");
          if (err.code === 17) {
            me.errorFlag = true;
            me.errorTitle = me.cc.error_messages.registration_number_incorrect;
            // me.vehicleNo = me.enterYourReg;

            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
              reg_error: me.errorTitle,
              reg_plate: this.enterYourReg,
              event: "reg_search_error"
            });
          } else {
            me.errorFlag = true;
            me.errorTitle = me.cc.error_messages.unknown_error;
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
              reg_error: me.errorTitle,
              reg_plate: "",
              event: "reg_search_error"
            });
          }
        }
      );
  }
  search(item) {
    let req = { id: this.typeId };
   // console.log("items", item);
   // console.log("id", req);
   this.setSearchFilterInLocalStorage();
    if (this.typeId) {
      this.router.navigate(["/recommendation", req.id]);
    } else if (item !== "search") {
      this.router.navigate(["/recommendation", item["$"]["id"]]);
    }
  }
  toggleDropdown(ip: HTMLElement) {
    const userData = localStorage.getItem("user");
    // if (!userData) {
    //   $("#login-modal").modal("show");
    //   return false;
    // }
    this.isDropdownShow = !this.isDropdownShow;
    if (this.isDropdownShow) {
      this.scroll(ip);
      this.enterYourReg = "";
    }
  }
  scroll(ip: HTMLElement) {
    ip.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      ip.scrollIntoView(true);
    }, 200);
  }
  setisDropdownShow(ip) {
    this.isDropdownShow = ip;
    this.items = [];
    this.enterYourReg = "";
  }
}
