import { Component, ViewChildren, QueryList, OnInit } from "@angular/core";
import { AlertComponent } from "src/app/alert/alert.component";
import { CommonService } from "src/app/service.service";
import { observable } from "rxjs/internal/symbol/observable";
import { CONFIG_CONSTANTS } from "../assets/config-constant";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { Angulartics2GoogleTagManager } from "angulartics2/gtm";
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
declare var $;
declare var require: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  title = "my-app";
  public enterYourReg;
  public items: any;
  public catag = [];
  public makeId;
  public modelId;
  public typeId;
  public makeList;
  public modelList;
  public typeList;
  public errorFlag = false;
  public lottieConfig: Object;
  public mobilelottieconfig: Object;
  public machineTitle;
  public makeTitle;
  public modelTitle;
  public typeTitle;
  public errorTitle;
  public nodeURL;
  @ViewChildren(AlertComponent) alerts: QueryList<AlertComponent>;
  constructor(
    private httpService: CommonService,
    private cc: CONFIG_CONSTANTS,
    private router: Router,
    angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private authService: AuthService) {
    angulartics2GoogleTagManager.startTracking();
    angulartics2GoogleAnalytics.startTracking();
  }
  ngOnInit() {
    if ((localStorage.getItem("user"))) {
      this.getProfile();
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100);
    });
  }

  getProfile() {
    this.httpService.doGetWithHeader(`${this.cc.path.api_url}/getprofile`).subscribe(function(res:any){
        // const result = JSON.parse(res);
				// if (result.status) {
          
				// }
			}
			, error => {
        this.authService.logout();
			});
  }
}

///GetTypeID2Recommendation
//GetCategoryList

//End of Api Requests
