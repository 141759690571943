import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from "src/app/service.service";
import { AuthService } from '../../auth/auth.service';
import { CONFIG_CONSTANTS } from "../../../assets/config-constant";
declare var $;

@Component({
  selector: 'finol-verify-email-alert',
  templateUrl: './verify-email-alert.component.html',
  styleUrls: ['./verify-email-alert.component.scss']
})
export class VerifyEmailAlertComponent implements OnInit {
  submitted:boolean = false;
  submitting:boolean = false;
	verifyEmailForm: FormGroup;
  errorMessage: String = null;
  constructor(private cc: CONFIG_CONSTANTS, private httpService: CommonService, private fb: FormBuilder, private authService: AuthService) {
    this.verifyEmailForm = fb.group({
			email_code: ["", [Validators.required, Validators.maxLength(10)]],
	  });
  }

  ngOnInit() {
  }

  get f() { return this.verifyEmailForm.controls; }

  verifyUserEmail() {
		const self = this;
    this.submitted = true;
    this.errorMessage = null;
    if (this.verifyEmailForm.valid) {
			this.submitting = true;
			const sendRequestData = this.verifyEmailForm.value;
      sendRequestData.email = this.httpService.registeredEmail;
			this.httpService.doPost(`${this.cc.path.api_url}/verifyemail`, sendRequestData).subscribe(function(res:any){
        const result = JSON.parse(res);
				self.submitting = false;
				if (result.status) {
					self.httpService.showSuccess(result.message);
          $("#verify-email-modal").modal("hide");
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          self.submitted=false;
          self.authService.setSessionData(result.data);
				}
				else {
					self.errorMessage = result.message;
				}
			}
			, error => {
				self.submitting = false;
				self.errorMessage = "Something went wrong";
			});
		}
  }
}
